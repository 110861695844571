import React from 'react';
import ReactDOM from 'react-dom';




export default class Lienformhome extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            text:this.props.text
        }
    }
    handleClickFormOpen = (e) => {
        e.preventDefault();
        let form = document.querySelector('#formcontactinde');
        form.style.display = "flex";
    }

    render(){
        return(
            <p>
                {this.state.text}
                <a className='fontbold' href="" onClick={(e) => this.handleClickFormOpen(e)}> cliquez ici</a>
            </p>
        )
    }
}
if (document.getElementById('lienformhome')) {
    const element = document.getElementById('lienformhome');
    let props = Object.assign({}, element.dataset);
    ReactDOM.render(<Lienformhome {...props}/>, document.getElementById('lienformhome'));
}